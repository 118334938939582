import sfcode from 'images/resource_slider/sfcode.png'
import x8chip from 'images/resource_slider/x8chip.png'
import chiphistogram from 'images/resource_slider/chiphistogram.png'

const demosArray: Resource[] = [
  {
    image: x8chip,
    title: 'Executing programs on X8 devices',
    difficulty: 'easy',
    link: 'https://strawberryfields.ai/photonics/demos/tutorial_X8.html',
  },
  {
    image: sfcode,
    title: 'Squeezer characterization',
    difficulty: 'easy',
    link: 'https://strawberryfields.ai/photonics/demos/squeezer_tests.html',
  },
  {
    image: chiphistogram,
    title: 'Quantum algorithms on the Xanadu quantum cloud',
    difficulty: 'easy',
    link: 'https://strawberryfields.ai/photonics/demos/tutorial_X8_demos.html',
  },
]

export default demosArray
