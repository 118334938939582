/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import Layout from 'components/Layout'

import Modal from 'components/Modal'
import VideoHeroLocal from 'components/VideoHeroLocal/VideoHeroLocal'
import SideNavSection from 'components/sections/sideNav'
import SideNavigation from 'components/sections/sideNav/Navigation'
import Slider from 'components/slider'

import HeadingArrowSvg from 'images/headingArrowSvg'
import ImageVideoPlayIconSvg from 'images/videoPlayIconSvg'

import heroVideo from 'videos/x-series/quantum-computers-trim_compressed.mp4'
import heroVideoThumbnail from 'videos/thumbnails/x-series-thumbnail.jpeg'
import demosArrayXproducts from 'data/demos/demosArrayXproducts'
import Seo from '../../components/Seo'
import { sideNavData } from 'types'

const SideNavMenu = () => {
  const sideNavInfo: sideNavData = {
    title: 'Navigation',
    links: [
      { title: 'X-Series', id: '#intro' },
      { title: 'Key Features', id: '#key-features' },
      { title: 'Getting Started', id: '#getting-started' },
      { title: 'Availability', id: '#availability' },
      { title: 'Demos', id: '#demos' },
      { title: 'Documentation', id: '#documentation' },
    ],
  }

  return <SideNavigation sideNavInfo={sideNavInfo} />
}

const SideNavMain = () => {
  return (
    <>
      <div id="intro" className="jumptarget">
        <h3>X-Series</h3>
        <p className="mt-8.5">
          Access the paradigm-shifting X-Series devices — the first photonic
          quantum computers deployed to the cloud. Powered by laser pulses, the
          X-Series push the boundaries of integrated quantum nanophotonics by
          demonstrating generation of on-chip squeezed states (the photonic
          analogue of a qubit) alongside fully programmable quantum gates and
          photon-number resolving detectors. Learn more about the X-Series
          devices in our publication in{' '}
          <a
            href="https://www.nature.com/articles/s41586-021-03202-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nature
          </a>
          .
        </p>
        <hr />
      </div>
      <div id="key-features" className="jumptarget">
        <h3>Key Features</h3>
        <ul className="checkbox">
          <li>
            Program the world’s first cloud-accessible integrated quantum
            nanophotonic device.
          </li>
          <li>
            Perform{' '}
            <a
              href="https://strawberryfields.ai/photonics/concepts/gbs.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gaussian boson sampling
            </a>{' '}
            with{' '}
            <a
              href="https://strawberryfields.ai/photonics/demos/tutorial_X8_demos.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              applications
            </a>{' '}
            in chemistry and graph networks.
          </li>
          <li>
            Evaluate on-chip{' '}
            <a
              href="https://strawberryfields.ai/photonics/demos/squeezer_tests.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              squeezing
            </a>
            , generating a pulse of photons analogous to a qubit.
          </li>
          <li>
            Use cutting-edge detectors to accurately count photons at the end of
            the circuit.
          </li>
        </ul>
      </div>
      <div className="standard-section-top-margin">
        <div id="getting-started" className="jumptarget">
          <h3>
            <a
              href="https://strawberryfields.ai/photonics/hardware/index.html"
              target="_blank"
              className="arrow-swipe-container"
              rel="noopener noreferrer"
            >
              Getting Started
              <span className="arrow-swipe ml-3.75">
                <span>
                  <span>
                    <HeadingArrowSvg svgClassName="inline-block" />
                  </span>
                </span>
              </span>
            </a>
          </h3>

          <p className="mt-5">
            When accessing X-Series devices, there&apos;s a wide range of
            options to check out:
          </p>
          <ul className="checkbox">
            <li>
              Use Strawberry Fields to submit an{' '}
              <a
                href="https://strawberryfields.ai/photonics/demos/tutorial_X8.html#executing-jobs"
                target="_blank"
                rel="noopener noreferrer"
              >
                example circuit
              </a>{' '}
              and generate samples.
            </li>
            <li>
              Simulate{' '}
              <a
                href="https://strawberryfields.ai/photonics/demos/tutorial_X8_demos.html#vibronic-spectra"
                target="_blank"
                rel="noopener noreferrer"
              >
                quantum chemistry
              </a>{' '}
              on the X8 device.
            </li>
            <li>
              Explore wider applications of Gaussian boson sampling through our{' '}
              <a
                href="https://strawberryfields.ai/photonics/applications.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                applications layer
              </a>
              , including embedding{' '}
              <a
                href="https://strawberryfields.ai/photonics/demos/tutorial_X8.html#embedding-bipartite-graphs"
                target="_blank"
                rel="noopener noreferrer"
              >
                bipartite graphs
              </a>
              .
            </li>
            <li>
              Interact with{' '}
              <a
                href="https://strawberryfields.readthedocs.io/en/stable/introduction/data.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                pre-generated datasets
              </a>{' '}
              and analyze sample distributions.
            </li>
            <li>
              <a
                href="https://strawberryfields.ai/photonics/demos/tutorial_X8.html#hardware-compilation"
                target="_blank"
                rel="noopener noreferrer"
              >
                Compile
              </a>{' '}
              more general photonic circuits into a hardware-compatible gateset.
            </li>
          </ul>

          <hr />

          <p className="mt-8.5">
            For advanced power users who want to control world-leading quantum
            hardware from the comfort of their own laptop:
          </p>
          <ul className="checkbox">
            <li>
              Install{' '}
              <a
                href="https://strawberryfields.readthedocs.io/en/stable/install.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Strawberry Fields
              </a>{' '}
              on your device and set up your own computer to{' '}
              <a
                href="https://strawberryfields.readthedocs.io/en/stable/introduction/photonic_hardware.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                submit jobs
              </a>{' '}
              .
            </li>
            <li>
              Become familiar with running programs on X-Series devices by
              following the demos below.
            </li>
            <li>
              Use the Strawberry Fields reference{' '}
              <a
                href="https://strawberryfields.ai/photonics/hardware/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </a>{' '}
              to gain an advanced understanding of executing photonic programs.
            </li>
          </ul>
        </div>
      </div>
      <div className="standard-section-top-margin">
        <div id="availability" className="jumptarget">
          <h3>Availability</h3>
          <ul className="checkbox">
            <li>Monday-Thursday, 15:00:00 - 23:00:00 UTC</li>
            <li className="mb-2">
              For access to X8, please{' '}
              <a
                href="mailto:hardware-access@xanadu.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us.
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>
      <div className="standard-section-top-margin">
        <div id="demos" className="jumptarget">
          <h3>
            <a
              href="https://strawberryfields.ai/photonics/hardware/index.html"
              target="_blank"
              className="arrow-swipe-container"
              rel="noopener noreferrer"
            >
              Demos
              <span className="arrow-swipe ml-3.75">
                <span>
                  <span>
                    <HeadingArrowSvg svgClassName="inline-block" />
                  </span>
                </span>
              </span>
            </a>
          </h3>
          <div className="mt-10">
            <Slider
              width="w-1/3"
              extraClass="slider-fade"
              demos={demosArrayXproducts}
            />
          </div>
        </div>
      </div>
      {/* DEMOS */}
      <div className="standard-section-top-margin">
        <div id="documentation" className="jumptarget">
          <h3>Documentation</h3>
          <p className="mt-10">
            Access the{' '}
            <a
              href="https://strawberryfields.readthedocs.io/en/stable/introduction/photonic_hardware.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              full documentation
            </a>
            , or read more about the hardware in our publication in{' '}
            <a
              href="https://arxiv.org/abs/2103.02109"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nature
            </a>
            .
          </p>
        </div>
      </div>
    </>
  )
}

const XSeriesPage = () => {
  return (
    <Layout>
      <div className="mb-15">
        <VideoHeroLocal
          copyElement={
            <>
              <p className="text-white subtitle opacity-70">// X-Series</p>
              <h1>Nanophotonic chip powered by laser pulses</h1>
              <div className="mt-5.5">
                <Modal
                  interaction={
                    <div className="inline watch-video">
                      <ImageVideoPlayIconSvg
                        circleColor="text-white"
                        widthAndHeight={30}
                      />
                      <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                        <span data-hover="Watch Video">Watch Video</span>
                      </span>
                    </div>
                  }
                  title={'How Xanadu’s Photonic Quantum Computers Work'}
                  content={
                    <div className="video-wrapper">
                      <iframe
                        src="https://www.youtube.com/embed/v7iAqcFCTQQ?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  }
                />
              </div>
            </>
          }
          video={heroVideo as string}
          thumbnail={heroVideoThumbnail as string}
        />
      </div>
      <SideNavSection nav={<SideNavMenu />} main={<SideNavMain />} />
    </Layout>
  )
}

export default XSeriesPage

export const Head = () => {
  return (
    <Seo
      title="X-Series"
      description="Gaussian boson sampling devices with squeezed-state qubits"
    />
  )
}
